import botAvatar from '../assets/sara_avatar.png'
import userAvatar from '../assets/userAvatar.jpg'
import { defaultQuickReplies } from "./quickReplys";
import { createTextBotMsg } from "../utils/msgManager";

export const getUUID = () => {
	var temp_url = URL.createObjectURL(new Blob())
	var uuid = temp_url.toString()
	URL.revokeObjectURL(temp_url)
	return uuid.substr(uuid.lastIndexOf("/") + 1)
}

const initialMessages = [
	createTextBotMsg('果小图进入对话，为你服务！', 'system'),
	createTextBotMsg(`你好：欢迎你的咨询，我是中国科学院大学图书馆的果小图，很高兴为你服务。
1. 你可以问我有关图书馆的问题，比如你可以问我“图书馆几点关门""图书馆有多少藏书?""图书馆在哪？"等，我尽可能回答你；
2. 你可以让我预约座位，对我说“座位预约”；
3. 你可以让我查找图书，对我说”书籍检索“；
4. 我的微信公众号已经开通啦，请在微信公众号搜索“中国科学院大学图书馆”，快来关注我吧。`),
];

function initNavBar() {
	return {
		title: '果小图'
	}
}

export function initBotConfig() {
	return {
		avatar: botAvatar
	}
}

function initUserConfig() {
	return {
		avatar: userAvatar,
		id: getUUID()
	}
}


export const BotConfig = {
	navbar: initNavBar(),
	robot: initBotConfig(),
	user: initUserConfig(), 	// 用户头像
	messages: initialMessages,
	quickReplies: defaultQuickReplies,//快捷短语
	placeholder: '随便输点...', // 输入框占位符
	toolbar: [
		{
			type: 'image',
			icon: 'image',
			title: '相册',
		},
	],
}
