// 默认快捷短语，可选
export const defaultQuickReplies = [
	{
		icon: 'search',
		name: '图书馆有多少藏书?',
		isNew: true,
		isHighlight: true,
	},
	{
		name: '你是机器人吗？',
		isNew: true,
		icon: 'smile',
	},
	{
		name: '图书馆几点开门?',
		isHighlight: true,
		icon: 'search',
	}
];

