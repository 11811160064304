import axios from "axios";
import { BotConfig } from "../compoments/initConfig";
import { createCardMsg, createImgBotMsg, createTextBotMsg } from "./msgManager";

const RasaRestUrl = 'https://robot.ucassc.com/api'
// const RasaRestUrl = 'http://localhost:5005/webhooks/rest/webhook'

export const postToRasa = async (userInput) => {

	return await axios.post(RasaRestUrl,
		{ 'sender': BotConfig.user.id, 'message': userInput }
	).then(function (response) {
		return response.data
	}).catch(function (error) {
		return [{ 'text': '稍微遇到点问题，请稍后再试～', 'recipient_id': BotConfig.user.id }]
	})
}

export const getRasaResponse = async (msg) => {
	const data = msg.content;

	if (msg.type === 'text') {
		return await postToRasa(data.text)
	}
}

export function parseResponse(res, reqType) {
	let botResponse = []

	if (res === undefined) {
		return botResponse
	}

	res.forEach((item) => {
		let msg;
		if (item.text !== undefined) {
			msg = createTextBotMsg(item.text)
			botResponse.push(msg)
		}

		if (item.image !== undefined) {
			let src = decodeURI(item.image)
			msg = createImgBotMsg(src)
			botResponse.push(msg)
		}

		if (item.buttons !== undefined) {
			msg = createCardMsg(item.buttons)
			botResponse.push(msg)
		}

		if (item.custom && item.custom.api) {
			let custom = item.custom
			if (custom.api === 'book_detail') {
				botResponse.push(msg)
				let indexUrl = `http://opac.las.ac.cn/F/${custom.param.code}?find_base=CNBK&find_base=WWBK&find_base=CNSER&find_base=WWSER&find_base=CAS03&find_base=CAS07&func=find-m&find_code=WRD&request=${custom.param.book_name}&filter_code_5=WSL&filter_request_5=&local_base=&filter_code_1=WLN&filter_request_1=&filter_code_2=WYR&filter_request_2=&filter_code_3=WYR&filter_request_3=&filter_code_4=WFM&filter_request_4=`;
				window.open(indexUrl, '_blank').focus();
			}
		}

	})
	return botResponse
}


